<template>
  <div class="bg">
    <div class="top mb8">
      <h3>个人信息</h3>
      <p><span>姓名</span>张海峰</p>
      <p><span>电话</span>15966668888</p>
    </div>
    <div class="top mb8">
      <h3>药品信息</h3>
      <p><span>药品名称</span>张海峰</p>
      <p><span>需求时间</span>15966668888</p>
      <p><span>药品数量</span>15966668888</p>
      <p><span>生产厂家</span>15966668888</p>
    </div>

    <div class="top mb8">
      <h3>备注</h3>
      <p class="sl">
        武汉市中心医院现在急需xxxxx靶向，希望同行有药的 的接用调配一下，感谢万分！
      </p>
    </div>
    <div class="top mb8">
      <h3>药品图片</h3>
      <van-grid :gutter="10">
        <van-grid-item
          v-for="value in 8"
          :key="value"
          icon="photo-o"
          @click="showImagePreview"
        >
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg" />
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  data() {
    return {};
  },
   components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  methods: {
    showImagePreview() {
      ImagePreview({
        images: [
          "https://img.yzcdn.cn/vant/apple-1.jpg",
          "https://img.yzcdn.cn/vant/apple-2.jpg",
        ],
        startPosition: 1,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  h3 {
    text-align: left;
    color: rgba(45, 45, 45, 1);
    margin-bottom: 16px;
  }
  .top {
    color: rgba(68, 68, 68, 1);
    padding: 16px;
    background: #fff;
    text-align: left;
    p {
      margin-bottom: 8px;
    }
    span {
      display: inline-block;
      width: 56px;
      margin-right: 16px;
    }
  }
}
</style>
